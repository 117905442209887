.picker-container {
  display: none;
}

.picker-container:target {
  display: block;
}

.link-list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.link-list li {
  display: inline-block;
}

.link-list li:not(:last-child) :after {
  content: " | ";
}

.timer-results[data-time]:before {
  content: "Time: " attr(data-time) "ms";
}
/*# sourceMappingURL=index.0ee78cf8.css.map */
